<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";

export default defineComponent({
    name: "currency-form",

    emits: ['saved'],

    props: {
        currency: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'currency/loading',
            'states': 'currency/states',
            'errors': 'currency/errors',
            currency: 'currency/item'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        }
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`currency/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create') {
                    const pathType = '/master-data/invoices/currencies'
                    this.$router.push(this.$t('routes.'+ pathType + '/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            const base = {}

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)))
        }
    },

    watch: {
        currency: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.setModel(value)
            }
        }
    },

    created() {
        this.setModel(this.currency)
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.code === false}"
                        class="form-label"
                        for="code"
                    >{{ $t('invoices::currencies.columns.code').ucFirst() }} *</label
                    >

                    <input
                        id="code"
                        v-model="model.code"
                        :class="{'border-danger': states.code === false}"
                        :placeholder="$t('invoices::currencies.placeholders.code').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.code === false}" class="invalid-feedback">{{ errors.code }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('invoices::currencies.columns.name').ucFirst() }} *</label
                    >

                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('invoices::currencies.placeholders.name').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.symbol === false}"
                        class="form-label"
                        for="symbol"
                    >{{ $t('invoices::currencies.columns.symbol').ucFirst() }} *</label
                    >

                    <input
                        id="symbol"
                        v-model="model.symbol"
                        :class="{'border-danger': states.symbol === false}"
                        :placeholder="$t('invoices::currencies.placeholders.symbol').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.symbol === false}" class="invalid-feedback">{{ errors.symbol }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.fractional_unit_name === false}"
                        class="form-label"
                        for="fractional_unit_name"
                    >{{ $t('invoices::currencies.columns.fractional_unit_name').ucFirst() }} *</label
                    >

                    <input
                        id="fractional_unit_name"
                        v-model="model.fractional_unit_name"
                        :class="{'border-danger': states.fractional_unit_name === false}"
                        :placeholder="$t('invoices::currencies.placeholders.fractional_unit_name').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.fractional_unit_name === false}" class="invalid-feedback">{{ errors.fractional_unit_name }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.fractional_unit_number === false}"
                        class="form-label"
                        for="fractional_unit_number"
                    >{{ $t('invoices::currencies.columns.fractional_unit_number').ucFirst() }} *</label
                    >

                    <input
                        id="fractional_unit_number"
                        v-model="model.fractional_unit_number"
                        :class="{'border-danger': states.fractional_unit_number === false}"
                        :placeholder="$t('invoices::currencies.placeholders.fractional_unit_number').ucFirst()"
                        class="form-control"
                        type="number"
                        step="0.01"
                    />
                    <div :class="{'d-block': states.fractional_unit_number === false}" class="invalid-feedback">{{ errors.fractional_unit_number }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.reference === false}"
                        class="form-label"
                        for="reference"
                    >{{ $t('invoices::currencies.columns.reference').ucFirst() }} *</label
                    >

                    <input
                        id="reference"
                        v-model="model.reference"
                        :class="{'border-danger': states.reference === false}"
                        :placeholder="$t('invoices::currencies.placeholders.reference').ucFirst()"
                        class="form-control"
                        type="number"
                        step="0.01"
                    />
                    <div :class="{'d-block': states.reference === false}" class="invalid-feedback">{{ errors.reference }}</div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-12 text-center">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
